import { createGlobalStyle } from 'styled-components';
import styledComponentsRhythm from '@ceteio/styled-components-rhythm';
import styledComponentsNormalize from './normalize';

const debugRhythm = false;
const rhythmHeight = 0.75; // rem units. With browser default, this is 16px * 0.75rem == 12px

const normalize = styledComponentsNormalize();
export const rhythm = styledComponentsRhythm({
  baseFontSize: 1,
  rhythmHeight,
  defaultLineHeight: 1.2,
  capHeights: {
    // Calculated with https://codepen.io/sebdesign/pen/EKmbGL?editors=0011
    'Titillium Web': 0.7,
  },
  debug: false,
});

function isDebugRhythmEnabledThenAddGrid() {
  return debugRhythm
    ? `
      html {
        background: linear-gradient(rgba(0, 255, 0, 0.15), rgba(0, 255, 0, 0.15) 1px, transparent 1px);
        background-size: 1px ${rhythmHeight}rem;
      }
    `
    : ``;
}

const GlobalStyle = createGlobalStyle`

  ${normalize}

  button,
  a {
    ::-moz-focus-inner {border:0;}
    :focus {
      border: 0;
      outline: none;
    }
  }

  button {
    background: none;
    color: inherit;
    cursor: pointer;
    border-width: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  h1, h2, h3, h4, h5, h6, p, span, small, button {
    margin: 0;
    padding: 0;
    border: 0;
  }

  html {
    background-color: ${({ theme }) => theme.colors.blue_1};
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }

  body {
    min-height: 100%;
    height:auto;
    font-family: 'Titillium Web', sans-serif;
    margin: 0;
    padding-top: 0 !important;
    ${({ theme }: any) => theme.setFontWithRhythm(`Titillium Web`, 1, 1)};
    scroll-behavior: smooth;
  }

  ul {
    padding: 0 0 0 32px;
    margin: 0;
  }

  #___gatsby {
    height: auto;
    > div {
      height: auto;
    }
  }

  ${rhythm.global}
  ${isDebugRhythmEnabledThenAddGrid()}

  .ReactModal__Overlay {
    z-index: 9;
    overflow: auto;
    opacity: 0;
    transform: translateY(-10%);
    transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 0.3s
        cubic-bezier(0.445, 0.05, 0.55, 0.95);
    background: ${({ theme }) => theme.colors.blue_1_65} !important;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(-10%);
  }

  .ReactModal__Content {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    width: 70% !important;
    max-width: 1336px !important;
    margin: 32px auto !important;
    padding: 0 !important;
    border: 0 !important;
    background: transparent !important;
  }

  --deckgo-highlight-code-scroll: hidden;


  figure {
    margin: 0;
  }
`;

export default GlobalStyle;
