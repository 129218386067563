import React from 'react';
import { Breakpoints } from 'react-use-breakpoints';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { StateProvider } from './src/state';
import {
  mainReducerInitialState,
  mainReducer,
} from './src/state/reducers/main';
import { Theme, sizes, screenSizes } from './src/styles/themeStyles';

const ReactDOM = require('react-dom');

// This is a bugfix related to:
// https://github.com/gatsbyjs/gatsby/issues/17914
// It's caused by different values provided by Breakpoint component
// betwen ssr and browser runtime.
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <ThemeProvider theme={Theme}>
      <StateProvider
        initialState={mainReducerInitialState}
        reducer={mainReducer}
      >
        <Breakpoints breakpoints={screenSizes}>{element}</Breakpoints>
      </StateProvider>
    </ThemeProvider>
  </HelmetProvider>
);

export const onServiceWorkerUpdateReady = () => window.location.reload(true);
