const locationReducerInitialState = {
  locationState: { current: `/` },
};

const locationReducer = (state, action) => {
  switch (action.type) {
    case `changeLocation`:
      return {
        ...state,
        ...action.newLocationState,
      };

    default:
      return state;
  }
};

const layoutStateReducerInitialState = {
  layoutState: {
    isFirstRun: true,
    isRightOpen: false,
    isLeftOpen: false,
    isAsideCollapsed: false,
    isMenuCollapsed: false,
    isSpinnerActive: true,
    elementRef: null,
  },
};

const layoutStateReducer = (state, action) => {
  switch (action.type) {
    case `setFirstRun`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `toggleSpinner`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `toggleRightMenu`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `toggleLeftMenu`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `collapseLeftMenu`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `collapseRightAside`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    case `setLayoutElementRef`:
      return {
        ...state,
        ...action.newLayoutState,
      };
    default:
      return state;
  }
};

const formsStateReducerInitialState = {
  formsState: {
    submitted: false,
    isError: false,
  },
};

const formsStateReducer = (state, action) => {
  switch (action.type) {
    case `stayInTouchFormSubmitted`:
      return {
        ...state,
        ...action.newSubmittedState,
      };
    case `stayInTouchFormError`:
      return {
        ...state,
        ...action.newSubmittedState,
        ...action.newErrorState,
      };

    case `stayInTouchFormResetError`:
      return {
        ...state,
        ...action.newErrorState,
      };

    default:
      return state;
  }
};

const newsletterStateReducerInitialState = {
  newsletterState: {
    submitted: false,
    isError: false,
    responseMsg: ``,
  },
};

const newsletterStateReducer = (state, action) => {
  switch (action.type) {
    case `newsletterSubmitted`:
      return {
        ...state,
        ...action.newSubmittedState,
      };
    case `newsletterError`:
      return {
        ...state,
        ...action.newSubmittedState,
        ...action.newErrorState,
      };

    case `newsletterResetError`:
      return {
        ...state,
        ...action.newErrorState,
      };

    default:
      return state;
  }
};

export const mainReducerInitialState = {
  ...locationReducerInitialState,
  ...layoutStateReducerInitialState,
  ...formsStateReducerInitialState,
  ...newsletterStateReducerInitialState,
};

export const mainReducer = (
  { layoutState, locationState, formsState, newsletterState },
  action,
) => ({
  locationState: locationReducer(locationState, action),
  layoutState: layoutStateReducer(layoutState, action),
  formsState: formsStateReducer(formsState, action),
  newsletterState: newsletterStateReducer(newsletterState, action),
});
