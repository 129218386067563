// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-about-index-tsx": () => import("./../../../src/templates/about/index.tsx" /* webpackChunkName: "component---src-templates-about-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-contact-index-tsx": () => import("./../../../src/templates/contact/index.tsx" /* webpackChunkName: "component---src-templates-contact-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-modal-index-tsx": () => import("./../../../src/templates/modal/index.tsx" /* webpackChunkName: "component---src-templates-modal-index-tsx" */),
  "component---src-templates-person-index-tsx": () => import("./../../../src/templates/person/index.tsx" /* webpackChunkName: "component---src-templates-person-index-tsx" */),
  "component---src-templates-processes-index-tsx": () => import("./../../../src/templates/processes/index.tsx" /* webpackChunkName: "component---src-templates-processes-index-tsx" */),
  "component---src-templates-project-index-tsx": () => import("./../../../src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */),
  "component---src-templates-projects-index-tsx": () => import("./../../../src/templates/projects/index.tsx" /* webpackChunkName: "component---src-templates-projects-index-tsx" */),
  "component---src-templates-service-index-tsx": () => import("./../../../src/templates/service/index.tsx" /* webpackChunkName: "component---src-templates-service-index-tsx" */),
  "component---src-templates-services-index-tsx": () => import("./../../../src/templates/services/index.tsx" /* webpackChunkName: "component---src-templates-services-index-tsx" */),
  "component---src-templates-tags-index-tsx": () => import("./../../../src/templates/tags/index.tsx" /* webpackChunkName: "component---src-templates-tags-index-tsx" */)
}

