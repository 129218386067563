import { css } from 'styled-components';
import { rgba, darken, lighten, rem } from 'polished';
import { rhythm } from './globalStyles';

export const screenSizes = {
  wide: 2400,
  desktop: 1366,
  tablet: 1024,
  phablet: 768,
  phone: 576,
  watch: 0,
};

const breakpoints = Object.keys(screenSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${rem(`${screenSizes[label]}px`)}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const Theme = {
  breakpoints,
  ...rhythm.theme,
  gradiants: {
    rainbow_1:
      'linear-gradient(45deg,#F638FB 0%,#F638FB 25%, #FEB707 60%, #FEB707 100%)',
    rainbow_1_x:
      'linear-gradient(165deg,#F638FB 0%,#F638FB 25%, #FEB707 60%, #FEB707 100%)',
    rainbow_2:
      'linear-gradient(165deg,#FEB707 0%,#FEB707 25%, #F638FB 75%, #F638FB 100%)',
  },
  tags: {
    red: {
      color: '#FFA8A8',
      bg: rgba('#FF8787', 0.15),
      colorHover: lighten(0.1, '#FFA8A8'),
      bgHover: rgba('#FF8787', 0.3),
    },
    pink: {
      color: '#FAA2C1',
      bg: rgba('#F783AC', 0.15),
      colorHover: lighten(0.1, '#FAA2C1'),
      bgHover: rgba('#F783AC', 0.3),
    },
    grape: {
      color: '#E599F7',
      bg: rgba('#DA77F2', 0.15),
      colorHover: lighten(0.1, '#E599F7'),
      bgHover: rgba('#DA77F2', 0.3),
    },
    violet: {
      color: '#B197FC',
      bg: rgba('#9775FA', 0.15),
      colorHover: lighten(0.1, '#B197FC'),
      bgHover: rgba('#9775FA', 0.3),
    },
    indigo: {
      color: '#91A7FF',
      bg: rgba('#748FFC', 0.15),
      colorHover: lighten(0.1, '#91A7FF'),
      bgHover: rgba('#748FFC', 0.3),
    },
    blue: {
      color: '#74C0FC',
      bg: rgba('#4DABF7', 0.15),
      colorHover: lighten(0.1, '#74C0FC'),
      bgHover: rgba('#4DABF7', 0.3),
    },
    cyan: {
      color: '#66D9E8',
      bg: rgba('#3BC9DB', 0.15),
      colorHover: lighten(0.1, '#66D9E8'),
      bgHover: rgba('#3BC9DB', 0.3),
    },
    teal: {
      color: '#63E6BE',
      bg: rgba('#38D9A9', 0.15),
      colorHover: lighten(0.1, '#63E6BE'),
      bgHover: rgba('#38D9A9', 0.3),
    },
    green: {
      color: '#8CE99A',
      bg: rgba('#69DB7C', 0.15),
      colorHover: lighten(0.1, '#8CE99A'),
      bgHover: rgba('#69DB7C', 0.3),
    },
    lime: {
      color: '#C0EB75',
      bg: rgba('#A9E34B', 0.15),
      colorHover: lighten(0.1, '#C0EB75'),
      bgHover: rgba('#A9E34B', 0.3),
    },
    yellow: {
      color: '#FFE066',
      bg: rgba('#FFD43B', 0.15),
      colorHover: lighten(0.1, '#FFE066'),
      bgHover: rgba('#FFD43B', 0.3),
    },
    orange: {
      color: '#FFC078',
      bg: rgba('#FFA94D', 0.15),
      colorHover: lighten(0.1, '#FFC078'),
      bgHover: rgba('#FFA94D', 0.3),
    },
  },
  colors: {
    magenta_1: '#684eaf',
    magenta_2: '#2c117a',
    magenta_2_00: rgba('#2c117a', 0),
    pink_1: '#FF55B6',
    pink_1_150: darken(0.25, '#FF55B6'),
    pink_2: '#F638FB',
    pink_3: '#D54898',
    blue_1: '#001529',
    blue_1_90: rgba('#001529', 0.9),
    blue_1_65: rgba('#001529', 0.65),
    blue_1_30: rgba('#001529', 0.3),
    blue_1_00: rgba('#001529', 0),
    blue_2: '#26136F',
    blue_2_30: rgba('#2D127B', 0.3),
    blue_3_20: rgba('#29156E', 0.2),
    blue_3_45: rgba('#29156E', 0.45),
    blue_3_60: rgba('#29156E', 0.6),
    blue_4: rgba('#011627', 0.95),
    gray_1: '#D9D9D9',
    gray_2: '#F0F2F5',
    gray_3: '#E8E8E8',
    gray_3_30: rgba('#C4C4C4', 0.3),
    gray_3_65: rgba('#C4C4C4', 0.65),
    gray_4: rgba('#ABB2BF', 0.95),
    white_1: '#FFFFFF',
    white_1_30: rgba('#FFFFFF', 0.3),
    white_1_45: rgba('#FFFFFF', 0.45),
    white_1_60: rgba('#FFFFFF', 0.6),
    white_1_75: rgba('#FFFFFF', 0.75),
    white_1_90: rgba('#FFFFFF', 0.9),
    purple_1: '#B894E7',
    purple_2: '#8144C8',
    purple_3: '#5D45A6',
    purple_4: '#181151',
    yellow_1: '#FEB707',
    yellow_2: '#FFA807',
    yellow_3: '#EE8600',
    yellow_4: '#EF6A00',
    red_1: '#FD2E00',
    red_2: '#BF1400',
  },
};
